var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label for=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"targetPath") || (depth0 != null ? lookupProperty(depth0,"targetPath") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"targetPath","hash":{},"data":data,"loc":{"start":{"line":4,"column":24},"end":{"line":4,"column":38}}}) : helper)))
    + "-usb-input\" class=\"control-label text-bold\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"usb_receipt_printer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":82},"end":{"line":4,"column":124}}}))
    + "</label>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"printers") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":12,"column":29}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":11,"column":39},"end":{"line":11,"column":45}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":11,"column":47},"end":{"line":11,"column":56}}}) : helper)))
    + "</option>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                    <option value=\"\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"missing_receipt_printer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":37},"end":{"line":14,"column":83}}}))
    + "</option>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label for=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"targetPath") || (depth0 != null ? lookupProperty(depth0,"targetPath") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"targetPath","hash":{},"data":data,"loc":{"start":{"line":28,"column":24},"end":{"line":28,"column":38}}}) : helper)))
    + "-network-input\" class=\"control-label text-bold\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"network_receipt_printer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":28,"column":86},"end":{"line":28,"column":132}}}))
    + "</label>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"receipt-printer-setting\">\r\n    <div data-ui=\"usb-receipt-printer\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showLabel") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":5,"column":15}}})) != null ? stack1 : "")
    + "\r\n        <div class=\"row-flex\">\r\n            <select name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"targetPath") || (depth0 != null ? lookupProperty(depth0,"targetPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetPath","hash":{},"data":data,"loc":{"start":{"line":8,"column":26},"end":{"line":8,"column":40}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"targetPath") || (depth0 != null ? lookupProperty(depth0,"targetPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetPath","hash":{},"data":data,"loc":{"start":{"line":8,"column":46},"end":{"line":8,"column":60}}}) : helper)))
    + "-usb-input\" class=\"form-control\" data-ui=\"select\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPrinters") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":15,"column":23}}})) != null ? stack1 : "")
    + "            </select>\r\n            <button data-ui=\"save-button\" class=\"btn designer-box pull-right rounded\">\r\n                "
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"save",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":43}}}))
    + "\r\n            </button>\r\n            <button data-ui=\"device-type-network\" class=\"btn designer-box pull-right rounded btn-icon\">\r\n                <i class=\"fas fa-wifi\"></i>\r\n            </button>\r\n        </div>\r\n    </div>\r\n\r\n    <div data-ui=\"network-receipt-printer\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showLabel") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "\r\n        <div class=\"row-flex\">\r\n            <div class=\"loader-wrapper\" data-name=\"network-loader\"><div class=\"loader\"></div></div>\r\n\r\n            <i data-ui=\"network-status-circle\" style=\"font-size:17px; margin-right: 5px;\" class=\"far fa-check-circle text-success\"></i>\r\n\r\n            <input data-ui=\"network-ip\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"targetPath") || (depth0 != null ? lookupProperty(depth0,"targetPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetPath","hash":{},"data":data,"loc":{"start":{"line":36,"column":44},"end":{"line":36,"column":58}}}) : helper)))
    + "-network-input\" class=\"form-control\" type=\"text\" placeholder=\""
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"enter_printer_ip",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":36,"column":120},"end":{"line":36,"column":159}}}))
    + "\">\r\n            <button data-ui=\"save-button\" class=\"btn designer-box pull-right rounded\">\r\n                "
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"save",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":38,"column":43}}}))
    + "\r\n            </button>\r\n            <button data-ui=\"device-type-usb\" class=\"btn designer-box pull-right rounded btn-icon\">\r\n                <i class=\"fab fa-usb\"></i>\r\n            </button>\r\n        </div>\r\n    </div>\r\n</div>\r\n\r\n";
},"useData":true});