var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-default btn-alt btn-regular btn-block\" data-action=\"production-groups\">\r\n            "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"configure_production_groups",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":21,"column":62}}}))
    + "\r\n        </button>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"deviceId") || (depth0 != null ? lookupProperty(depth0,"deviceId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"deviceId","hash":{},"data":data,"loc":{"start":{"line":48,"column":16},"end":{"line":48,"column":28}}}) : helper)))
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                -\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"left\">\r\n    <h2>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"settings",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":39}}}))
    + "</h2>\r\n    <p class=\"h5\">\r\n        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"here_you_can_update_the_settings_of_this_pos_dot",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":79}}}))
    + "\r\n        <br>\r\n        <br>\r\n    </p>\r\n    <div data-region=\"pin-device\"></div>\r\n    <div data-region=\"receipt-printer\"></div>\r\n    <div data-region=\"printer-settings\"></div>\r\n    <div data-region=\"scale\"></div>\r\n    <div data-region=\"keyboard-layout\"></div>\r\n\r\n\r\n    <p class=\"h5\" style=\"margin-top: 25px;\">\r\n        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"there_are_no_other_devices_connected_that_can_be_configured_you_have_one_that_is_connected_make_sure_its_properly_connected_and_turned_on_dot",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":16,"column":172}}}))
    + "\r\n    </p>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showProductionGroup") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "\r\n    <div data-ui=\"sound-section\">\r\n        <h3>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"sound",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":26,"column":40}}}))
    + "</h3>\r\n        <button class=\"btn btn-default btn-alt btn-regular btn-block\" data-action=\"test-speaker\">\r\n            <i class=\"fas fa-volume\"></i>\r\n            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"test_speaker",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":29,"column":47}}}))
    + "\r\n            <span data-ui=\"message\"></span>\r\n        </button>\r\n    </div>\r\n</div>\r\n<div class=\"right\">\r\n    <h2>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"sales_channel_settings",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":35,"column":53}}}))
    + "</h2>\r\n    <p class=\"h5\">\r\n        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"below_you_ll_find_the_current_settings_for_this_sales_channel_these_settings_can_be_changed_under_your_sales_channel_settings_in_the_back_office_dot",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":37,"column":179}}}))
    + "\r\n    </p>\r\n    <div data-region=\"sales-channel\"></div>\r\n    <div data-region=\"payment-methods\"></div>\r\n    <div data-region=\"receipt-example\"></div>\r\n</div>\r\n<div class=\"footer\">\r\n    <small class=\"device-information\" data-ui=\"device-info\">\r\n        <span class=\"tag\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"device_id",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":45,"column":26},"end":{"line":45,"column":58}}}))
    + ":</span>\r\n        <span class=\"data\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deviceId") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":47,"column":12},"end":{"line":51,"column":19}}})) != null ? stack1 : "")
    + "        </span>\r\n\r\n        <span class=\"tag\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"version",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":54,"column":26},"end":{"line":54,"column":56}}}))
    + ":</span>\r\n        <span class=\"data\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"version") || (depth0 != null ? lookupProperty(depth0,"version") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"version","hash":{},"data":data,"loc":{"start":{"line":55,"column":27},"end":{"line":55,"column":38}}}) : helper)))
    + "</span>\r\n\r\n        <span class=\"tag\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"account",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":57,"column":26},"end":{"line":57,"column":56}}}))
    + ":</span>\r\n        <span class=\"data\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"account") || (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"account","hash":{},"data":data,"loc":{"start":{"line":58,"column":27},"end":{"line":58,"column":38}}}) : helper)))
    + "</span>\r\n\r\n        <span class=\"tag\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"user",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":60,"column":26},"end":{"line":60,"column":53}}}))
    + ":</span>\r\n        <span class=\"data\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"user") || (depth0 != null ? lookupProperty(depth0,"user") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"user","hash":{},"data":data,"loc":{"start":{"line":61,"column":27},"end":{"line":61,"column":35}}}) : helper)))
    + "</span>\r\n    </small>\r\n\r\n    <i class=\"fas fa-redo-alt text-info fa-3x\" data-action=\"reload\" style=\"margin: 0 25px;\"></i>\r\n</div>\r\n";
},"useData":true});