var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":10,"column":27},"end":{"line":10,"column":33}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":10,"column":35},"end":{"line":10,"column":43}}}) : helper)))
    + "</option>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label for=\"printer::"
    + alias4(((helper = (helper = lookupProperty(helpers,"printerId") || (depth0 != null ? lookupProperty(depth0,"printerId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"printerId","hash":{},"data":data,"loc":{"start":{"line":1,"column":21},"end":{"line":1,"column":34}}}) : helper)))
    + "\" class=\"control-label text-bold\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":1,"column":68},"end":{"line":1,"column":77}}}) : helper)))
    + "</label>\r\n<div class=\"select-group\">\r\n    <select name=\"printer::"
    + alias4(((helper = (helper = lookupProperty(helpers,"printerId") || (depth0 != null ? lookupProperty(depth0,"printerId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"printerId","hash":{},"data":data,"loc":{"start":{"line":3,"column":27},"end":{"line":3,"column":40}}}) : helper)))
    + "\" id=\"printer::"
    + alias4(((helper = (helper = lookupProperty(helpers,"printerId") || (depth0 != null ? lookupProperty(depth0,"printerId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"printerId","hash":{},"data":data,"loc":{"start":{"line":3,"column":55},"end":{"line":3,"column":68}}}) : helper)))
    + "\" class=\"form-control\" data-ui=\"main-type\">\r\n        <option value=\"\">"
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"don_t_use_this_printer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":25},"end":{"line":4,"column":70}}}))
    + "</option>\r\n        <option value=\"shelve\">"
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"pricetags",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":63}}}))
    + "</option>\r\n        <option value=\"sticker\">"
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"stickers",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":63}}}))
    + "</option>\r\n    </select>\r\n    <select class=\"form-control\" style=\"display: none;\" data-ui=\"sticker-sub-type\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"mediaTypes") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":11,"column":17}}})) != null ? stack1 : "")
    + "    </select>\r\n</div>";
},"useData":true});