var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"posName") || (depth0 != null ? lookupProperty(depth0,"posName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"posName","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":19}}}) : helper)))
    + "\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"sales_channel_not_set",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":52}}}))
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label class=\"control-label text-bold col-xs-4\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"sales_channel",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":1,"column":48},"end":{"line":1,"column":84}}}))
    + ":</label>\r\n<div class=\"col-xs-8 h5\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"posName") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "\r\n    <button data-action=\"change-channel\" class=\"btn designer-box pull-right rounded\"><i class=\"fa fa-gear\"></i>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"change",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":111},"end":{"line":9,"column":140}}}))
    + "</button>\r\n</div>\r\n";
},"useData":true});